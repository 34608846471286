.calendar-card{
    display: flex;
    color: $white;
    margin-bottom: 30px;

    &:last-of-type{
        margin-bottom: 0;
    }

    &-date{
        align-items: center;
        background: $secondary-dark;
        box-shadow: 5px 5px 0px rgba(51, 51, 51, 0.3);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        font-family: $blogger;
        font-weight: $bold;
        justify-content: center;
        letter-spacing: 0.05em;
        padding: 15px 0;
        text-align: center;
        text-transform: uppercase;
        max-width: 130px;
        width: 100%;

        @media (max-width: 520px) {
            max-width: 80px;
        }

        @media (max-width: 425px) {
            max-width: 80px;
        }

        &--day{
            font-size: 60px;

            @media (max-width: 520px) {
                font-size: 36px;
            }            
        }

        &--month{
            font-size: 36px;

            @media (max-width: 520px) {
                font-size: 22px;
            }
        }
    }

    &-info{
        background: $secondary;
        box-shadow: 5px 5px 0px rgba(51, 51, 51, 0.3);
        border-radius: 15px;
        font-family: $lato;        
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 1.25;
        padding: 20px 30px;
        text-transform: uppercase;
        width: 100%;

        @media (max-width: 520px) {
            font-size: 9px;
            padding: 10px 15px;
        }        

        &--title{
            font-weight: $extra-bold;
            font-size: 22px;
            margin-bottom: 15px;

            @media (max-width: 520px) {
                font-size: 14px;
                margin-bottom: 10px;
            }

            @media (max-width: 425px) {
                font-size: 12px;
                margin-bottom: 5px;
            }
        }

        &--content{
            align-items: stretch;
            display: flex;
            margin-bottom: 15px;

            @media (max-width: 520px) {
                margin-bottom: 10px;
            }

            @media (max-width: 425px) {
                margin-bottom: 5px;
            }

            .calendar-card-icon-desc{
                @extend .icon;
                background: url('../../../svg/icon-smile.svg');
                background-repeat: no-repeat;
                padding-right: 15px;

                @media (max-width: 520px) {
                    background-size: 15px;
                }
            }

            p{
                @extend .card-content
            }
        }

        &--hour{
            align-items: flex-end;
            display: flex;

            .calendar-card-icon-hour{
                @extend .icon;
                background: url('../../../svg/icon-clock.svg');
                background-repeat: no-repeat;
                padding-right: 15px;

                @media (max-width: 520px) {
                    background-size: 15px;
                }
            }

            p{
                @extend .card-content
            }
        }
    }
}
