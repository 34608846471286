/* Colors */
$primary: #F06445;
$primary-dark: #F05225;
$secondary: #7D4E9F;
$secondary-dark: #4A3176;
$secondary-light: #9593EC;
$white: #FFF;
$black: #000;
$gray: #DDDDDD;
$light-red: #ff9d9d;
$light-brown: #ff9d9d99;
$light-violet: #4a317640;
$error: #b42929;

/* Overlays*/
$overlay-3: rgba(0, 0, 0, 0.3);
$overlay-4: rgba(0, 0, 0, 0.4);
$overlay-5: rgba(0, 0, 0, 0.5);


/* Wheights*/
$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$extra-bold: 900;

/* Shadows */
$shadow50: rgba(50, 50, 50, 0.3);

/* Fonts */
$blogger: 'Blogger Sans', sans-serif;
$lato: 'Lato', sans-serif;

/* Gradients*/
$gradientOrangeViolet: linear-gradient(to right, #F06445 0, #F06445 50%, #7D4E9F 50%);
$gradientViolet: linear-gradient(0deg, $secondary 60%, #7d4e9f00 100%);
$gradientOrange: linear-gradient(0deg, $primary 50%, #f0644500 110%);