@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-Bold.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('fonts/Blogger_Sans/BloggerSans-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), local('Lato'), url('fonts/Lato/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
